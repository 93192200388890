<template>
    <div :style="{width:width+'px',height:height+'px',position:'relative'}">
        <div class="black" :style="{width:width+'px'}">
            <div class="bkey" @mousedown="play(2)" @mouseout="hide(2)" @mouseup="hide(2)" :style="{opacity:sty[1].show.value==true?1:0,background:sty[1].color.value,width:bKeyWidth+'px',left:sty[1].left.value+'px'}">
                <audio ref="A_" controls preload="false" :src="tones[1]" :style="{visibility:'hidden'}" ></audio>
            </div>
        </div>

        <div class="white" :style="{width:width+'px'}">
            <div class="wkey" @mousedown="play(1)" @mouseout="hide(1)" @mouseup="hide(1)" :style="{opacity:sty[0].show.value==true?1:0,background:sty[0].color.value,width:wKeyWidth+'px'}">
                <audio ref="A" controls preload="false" :src="tones[0]" :style="{visibility:'hidden'}" ></audio>
            </div>
            <div class="wkey" @mousedown="play(3)" @mouseout="hide(3)" @mouseup="hide(3)" :style="{opacity:sty[2].show.value==true?1:0,background:sty[2].color.value,width:wKeyWidth+'px'}">
                <audio ref="B" controls preload="false" :src="tones[2]" :style="{visibility:'hidden'}" ></audio>
            </div>
        </div>
        <img :style="{width:width+'px',height:height+'px'}" src="../../../assets/images/headPiece.png"/>
    </div>
</template>

<script>
import {ref} from 'vue'
import {getElementPagePosition,charUpEffect} from '../../../hooks/index'
export default {
    name:'headPiece',
    props: {
        tones: {
            type:Array,
            default:function(){
                return [];
            }
        },
        width:{
            type:String,
            default:'48'
        },
        height:{
            type:String,
            default:'250'
        }
    },
    setup(props){
        // const A=ref(null);
        // const A_=ref(null);
        // const B=ref(null);
        const tsObj={A:ref(null),A_:ref(null),B:ref(null)}
        const play=(pos)=>{
            if(pos==1){
                tsObj.A.value.currentTime=0;
                tsObj.A.value.play();
                let ps=getElementPagePosition(tsObj.A.value);
                charUpEffect({},ps,tsObj.A.value.parentElement.parentElement.parentElement.parentElement);
            }else if(pos==2){
                tsObj.A_.value.currentTime=0;
                tsObj.A_.value.play();
                let ps=getElementPagePosition(tsObj.A_.value);
                //console.log(ps);
                //console.log(tsObj.A_.value.parentElement.parentElement.parentElement)
                charUpEffect({},ps,tsObj.A_.value.parentElement.parentElement.parentElement.parentElement);
            }else if(pos==3){
                tsObj.B.value.currentTime=0;
                tsObj.B.value.play();
                let ps=getElementPagePosition(tsObj.B.value);
                //console.log(ps);
                charUpEffect({},ps,tsObj.B.value.parentElement.parentElement.parentElement.parentElement);
            }
            sty[pos-1].show.value=true;
        }

        const hide=(pos)=>{
            sty[pos-1].show.value=false;
        };

        let sty=[];
        for(var i=0;i<3;i++){
            var obj=new Object();
            obj.show=ref(false);
            obj.color=ref('rgba(0,0,255,0.8)');
            obj.left=ref(0);
            sty[i]=obj;
        }

        let bKeyWidth=ref(0);
        let wKeyWidth=ref(0);
        bKeyWidth.value=props.width/4;
        wKeyWidth.value=props.width/2-6;
        sty[1].left.value=bKeyWidth.value+bKeyWidth.value/2;
        return {...tsObj,play,tsObj,sty,hide,bKeyWidth,wKeyWidth}
    }
}
</script>

<style scoped lang='less'>
.black{
    margin-top: 20px;
    height: 100px;
    //border: solid red;
    position:absolute;
}

.white{
    bottom: 1px;
    margin-bottom: 5px;
    height: 80px;
    //border: solid red;
    position:absolute;
    display: flex;
}

.bkey{
    position: absolute;
    border-radius: 5px;
    height:70px;
    &:hover{
        cursor: pointer;
    }
}

.wkey{
    position: relative;
    border-radius: 5px;
    margin: 3px;
    height:70px;
    &:hover{
        cursor: pointer;
    }
}

img{
    -webkit-user-drag: none;
}
</style>