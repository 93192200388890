<template>
    <div :style="{width:width+'px',height:height+'px',position:'relative'}">
        <div class="black" :style="{width:width+'px'}">
            <div class="bkey" @mousedown="play(2,'rgba(0,0,255,0.8)')" @mouseout="hide(2)" @mouseup="hide(1)" :style="{opacity:sty[1].show.value==true?1:0,background:sty[1].color.value,width:bKeyWidth+'px',left:sty[1].left.value+'px'}">
                <audio ref="C_"  controls :src="tones[1]" :style="{visibility:'hidden'}" ></audio>
            </div>
            <div class="bkey" @mousedown="play(4,'rgba(0,0,255,0.8)')" @mouseout="hide(4)" @mouseup="hide(4)" :style="{opacity:sty[3].show.value==true?1:0,background:sty[3].color.value,width:bKeyWidth+'px',left:sty[3].left.value+'px'}">
                <audio ref="D_"  controls :src="tones[3]" :style="{visibility:'hidden'}" ></audio>
            </div>
            <div class="bkey" @mousedown="play(7,'rgba(0,0,255,0.8)')" @mouseout="hide(7)" @mouseup="hide(7)" :style="{opacity:sty[6].show.value==true?1:0,background:sty[6].color.value,width:bKeyWidth+'px',left:sty[6].left.value+'px'}">
                <audio ref="F_"  controls :src="tones[6]" :style="{visibility:'hidden'}" ></audio>
            </div>
            <div class="bkey" @mousedown="play(9,'rgba(0,0,255,0.8)')" @mouseout="hide(9)" @mouseup="hide(9)" :style="{opacity:sty[8].show.value==true?1:0,background:sty[8].color.value,width:bKeyWidth+'px',left:sty[8].left.value+'px'}">
                <audio ref="G_"  controls :src="tones[8]" :style="{visibility:'hidden'}" ></audio>
            </div>
            <div class="bkey" @mousedown="play(11,'rgba(0,0,255,0.8)')" @mouseout="hide(11)" @mouseup="hide(11)" :style="{opacity:sty[10].show.value==true?1:0,background:sty[10].color.value,width:bKeyWidth+'px',left:sty[10].left.value+'px'}">
                <audio ref="A_"  controls :src="tones[10]" :style="{visibility:'hidden'}" ></audio>
            </div>
        </div>

        <div class="white" :style="{width:width+'px'}">
            <div class="wkey" @mousedown="play(1,'rgba(0,0,255,0.8)')" @mouseout="hide(1)" @mouseup="hide(1)" :style="{opacity:sty[0].show.value==true?1:0,background:sty[0].color.value,width:wKeyWidth+'px'}">
                <audio ref="C" controls :src="tones[0]" :style="{visibility:'hidden'}"></audio>
            </div>
            <div class="wkey" @mousedown="play(3,'rgba(0,0,255,0.8)')" @mouseout="hide(3)" @mouseup="hide(3)" :style="{opacity:sty[2].show.value==true?1:0,background:sty[2].color.value,width:wKeyWidth+'px'}">
                <audio ref="D" controls :src="tones[2]" :style="{visibility:'hidden'}" ></audio>
            </div>
            <div class="wkey" @mousedown="play(5,'rgba(0,0,255,0.8)')" @mouseout="hide(5)" @mouseup="hide(5)" :style="{opacity:sty[4].show.value==true?1:0,background:sty[4].color.value,width:wKeyWidth+'px'}">
                <audio ref="E" controls :src="tones[4]" :style="{visibility:'hidden'}" ></audio>
            </div>
            <div class="wkey" @mousedown="play(6,'rgba(0,0,255,0.8)')" @mouseout="hide(6)" @mouseup="hide(6)" :style="{opacity:sty[5].show.value==true?1:0,background:sty[5].color.value,width:wKeyWidth+'px'}">
                <audio ref="F" controls :src="tones[5]" :style="{visibility:'hidden'}" ></audio>
            </div>
            <div class="wkey" @mousedown="play(8,'rgba(0,0,255,0.8)')" @mouseout="hide(8)" @mouseup="hide(8)" :style="{opacity:sty[7].show.value==true?1:0,background:sty[7].color.value,width:wKeyWidth+'px'}">
                <audio ref="G" controls :src="tones[7]" :style="{visibility:'hidden'}" ></audio>
            </div>
            <div class="wkey" @mousedown="play(10,'rgba(0,0,255,0.8)')" @mouseout="hide(10)" @mouseup="hide(10)" :style="{opacity:sty[9].show.value==true?1:0,background:sty[9].color.value,width:wKeyWidth+'px'}">
                <audio ref="A" controls :src="tones[9]" :style="{visibility:'hidden'}" ></audio>
            </div>
            <div class="wkey" @mousedown="play(12,'rgba(0,0,255,0.8)')" @mouseout="hide(12)" @mouseup="hide(12)" :style="{opacity:sty[11].show.value==true?1:0,background:sty[11].color.value,width:wKeyWidth+'px'}">
                <audio ref="B" controls :src="tones[11]" :style="{visibility:'hidden'}" ></audio>
            </div>
        </div>
        
        <img :style="{width:width+'px',height:height+'px'}" src="../../../assets/images/piece.png"/>
        
    </div>
</template>

<script>
import {ref} from 'vue'
import {charUpEffect,getElementPagePosition} from '../../../hooks/index'
export default {
    name:'Piece',
    props: {
        tones: {
            type:Array,
            default:function(){
                return [];
            }
        },
        width:{
            type:String,
            default:'168'
        },
        height:{
            type:String,
            default:'250'
        }
    },
    setup(props){
        const tsObj={C:ref(null),C_:ref(null),D:ref(null),D_:ref(null),E:ref(null),F:ref(null),F_:ref(null),G:ref(null),G_:ref(null),A:ref(null),A_:ref(null),B:ref(null)}
        let ts=[];
        ts[0]=tsObj.C;
        ts[1]=tsObj.C_;
        ts[2]=tsObj.D;
        ts[3]=tsObj.D_;
        ts[4]=tsObj.E;
        ts[5]=tsObj.F;
        ts[6]=tsObj.F_;
        ts[7]=tsObj.G;
        ts[8]=tsObj.G_;
        ts[9]=tsObj.A;
        ts[10]=tsObj.A_;
        ts[11]=tsObj.B;
        

        let sty=[];
        for(var i=0;i<12;i++){
            var obj=new Object();
            obj.show=ref(false);
            obj.color=ref('rgba(255,0,0,0.7)');
            obj.left=ref(0);
            sty[i]=obj;
        }

        const play=(pos,color)=>{
            ts[pos-1].value.currentTime=0;
            ts[pos-1].value.play();
            sty[pos-1].show.value=true;
            sty[pos-1].color.value=color
            let ps=getElementPagePosition(ts[pos-1].value);
            charUpEffect({},ps,ts[pos-1].value.offsetParent.offsetParent.offsetParent.offsetParent);
        }

        const hide=(pos)=>{
            sty[pos-1].show.value=false;
        };

        const hideAll=()=>{
            for(var i=0;i<sty.length;i++){
                sty[i].show.value=false;
            }
        }

        let bKeyWidth=ref(0);
        let wKeyWidth=ref(0);
        bKeyWidth.value=props.width/14;
        wKeyWidth.value=props.width/7-6;
        sty[1].left.value=bKeyWidth.value+bKeyWidth.value/2;
        sty[3].left.value=bKeyWidth.value*3+bKeyWidth.value/2;
        sty[6].left.value=bKeyWidth.value*7+bKeyWidth.value/2;
        sty[8].left.value=bKeyWidth.value*9+bKeyWidth.value/2;
        sty[10].left.value=bKeyWidth.value*11+bKeyWidth.value/2;
        
        return {...tsObj,play,hide,ts,bKeyWidth,wKeyWidth,sty,hideAll}
    }
}
</script>

<style scoped lang='less'>
.black{
    margin-top: 20px;
    height: 80px;
    //border: solid red;
    position:absolute;
}

.white{
    bottom: 1px;
    margin-bottom: 5px;
    height: 80px;
    //border: solid red;
    position:absolute;
    display: flex;
}

.bkey{
    position: absolute;
    border-radius: 5px;
    height:70px;
    &:hover{
        cursor: pointer;
    }
}

.wkey{
    position: relative;
    border-radius: 5px;
    margin: 3px;
    height:70px;
    &:hover{
        cursor: pointer;
    }
}

img{
    -webkit-user-drag: none;
}

// b{
//     position: absolute;
//     left: 5px;
//     top: 162px;
//     color: red;
//     border: solid;
//     z-index: 99999;
// }

</style>